// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import '@hotwired/turbo';

import './vendor/bootstrap';

import SelectorEngine from 'bootstrap/dom/selector-engine';

/**
 * ------------------------------------------------------------------------
 * Main application
 * ------------------------------------------------------------------------
 */

window.addEventListener('load', () => {
  document.documentElement.classList.remove('no-js');
});

document.addEventListener('turbo:load', () => {
  const sentinel = SelectorEngine.findOne('#sticky-observer');
  const header = SelectorEngine.findOne('#header');

  const navbarObserver = new IntersectionObserver(([entry]) =>
    header.classList.toggle('header-scrolling', !entry.isIntersecting)
  );
  navbarObserver.observe(sentinel);
  SelectorEngine.findOne('body').scrollTo(0, 0);
});
